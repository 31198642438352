import { json, type LoaderFunction } from "@remix-run/cloudflare";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRevalidator,
  useRouteError,
  isRouteErrorResponse,
} from "@remix-run/react";
import { createBrowserClient, createServerClient } from '@supabase/ssr';
import type { Database } from '~/types/supabase';
import type { Session, SupabaseClient } from '@supabase/supabase-js';
import { useEffect, useState } from 'react';
import "./tailwind.css";

type Env = {
  SUPABASE_URL: string;
  SUPABASE_ANON_KEY: string;
};

type LoaderData = {
  env: Env;
  session: Session | null;
};

export type SupabaseOutletContext = {
  supabase: SupabaseClient<Database>;
  session: Session | null;
};

export const loader: LoaderFunction = async ({ request, context }) => {
  const response = new Response();
  const env = context.cloudflare.env as Env;

  if (!env.SUPABASE_URL || !env.SUPABASE_ANON_KEY) {
    console.error("Supabase environment variables are not defined");
    throw new Error("Supabase configuration is missing");
  }

  const supabase = createServerClient<Database>(
    env.SUPABASE_URL,
    env.SUPABASE_ANON_KEY,
    {
      cookies: {
        get: (key) => request.headers.get('Cookie')?.match(new RegExp(`(^|;\\s*)${key}=([^;]*)`))?.at(2) ?? null,
        set: (key, value, options) => {
          response.headers.append('Set-Cookie', `${key}=${value}${options ? `; ${Object.entries(options).map(([k, v]) => `${k}=${v}`).join('; ')}` : ''}`);
        },
        remove: (key, options) => {
          response.headers.append('Set-Cookie', `${key}=; Max-Age=0${options ? `; ${Object.entries(options).map(([k, v]) => `${k}=${v}`).join('; ')}` : ''}`);
        },
      },
    }
  );

  const {
    data: { session },
  } = await supabase.auth.getSession();

  const data: LoaderData = {
    env: {
      SUPABASE_URL: env.SUPABASE_URL,
      SUPABASE_ANON_KEY: env.SUPABASE_ANON_KEY,
    },
    session,
  };

  return json<LoaderData>(data, { headers: response.headers });
};

export default function App() {
  const { env, session: initialSession } = useLoaderData<LoaderData>();
  const { revalidate } = useRevalidator();
  const [supabase] = useState(() =>
    createBrowserClient<Database>(env.SUPABASE_URL, env.SUPABASE_ANON_KEY)
  );
  const [session, setSession] = useState(initialSession);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, newSession) => {
      if (event === 'SIGNED_IN' || event === 'SIGNED_OUT') {
        setSession(newSession);
        revalidate();
      }
    });

    return () => subscription.unsubscribe();
  }, [supabase, revalidate]);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <Outlet context={{ supabase, session }} />
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  console.error(error);

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <title>Oops!</title>
      </head>
      <body>
        <div className="error-container">
          <h1>Oops! Something went wrong</h1>
          {isRouteErrorResponse(error) ? (
            <>
              <h2>{error.status} {error.statusText}</h2>
              <p>{error.data}</p>
            </>
          ) : error instanceof Error ? (
            <p>{error.message}</p>
          ) : (
            <p>Unknown error</p>
          )}
        </div>
        <Scripts />
      </body>
    </html>
  );
}